export const URLS = {
  ARTISAN: 'artisan-account',
  AUTH: 'auth',
  ADD_BUILDING: 'add-building',
  BUILDINGS: 'buildings',
  EDIT_BUILDING: 'edit-building',
  UPDATE_BUILDING: 'update-building',
  HOUSINGS: 'housings',
  HOUSING_RECORD: 'housing-record',
  HOUSING_ADD: 'add-housing',
  INTERVENTIONS: 'interventions',
  INTERVENTION_ADD: 'add-intervention',
  INTERVENTION_VALIDATION: 'intervention-validation',
  INTERVENTION_EDIT: 'edit-intervention',
  MANAGER: 'manager-account',
  SUBSCRIPTION: 'subscription',
  REGISTRATION: 'registration',
  USER_CHOICE: 'user-choice',
  EMAIL_VALIDATION: 'email-validation',
  PROFILE: 'profile',
  PROFILE_EDIT: 'edit-profile',
  PRESTATIONS: 'prestations',
  ADD_CARD: 'add-card',
  THIRD_PARTY_BILLING: 'facturation-tiers',
  INVOICES: 'invoices',
  CONFIRM: 'confirmation',
}
